import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SuccessSignup from "../sections/successSignup"

function CodeInvite() {
  return (
    <Layout>
      <SEO title="Baixar App" />
      <SuccessSignup />
    </Layout>
  );
}

export default CodeInvite;